import {
  Autocomplete,
  Button,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import CustomNumberInput from "components/CustomNumberInput";
import AddLocationModal from "components/lists/locations/AddLocationModal";
import DateTimePickerActionBar from "components/utils/DatePickerActionBar";
import { ENTITY } from "constants/entities";
import { DATE_VIEWS, STATUS_CODES } from "constants/index";
import {
  ARTICLES_URL,
  ARTICLE_LOCATIONS_URL,
  LOCATIONS_URL,
} from "constants/urls";
import { dateISOFormat } from "helpers";
import useCrud from "hooks/useCrud";
import { useToast } from "hooks/useToast";
import { useSession } from "providers/SessionProvider";
import { useEffect, useMemo, useCallback, useState } from "react";
import { RxPlus } from "react-icons/rx";
import {
  Article,
  ArticleLocation,
  IncomeMovementArticleRowProps,
  LocationBase,
  MovementArticle,
} from "types";

export default function AddMovementArticleRow({
  movement,
  articleInput,
  rowIndex,
  onMovementArticleAdd,
  locations,
  stores,
}: IncomeMovementArticleRowProps) {
  const { showSnackBar } = useToast();
  const [amount, setAmount] = useState<number>(0);
  const [selectedLocation, setSelectedLocation] = useState<{
    id: number | undefined;
    label: string;
  }>({ id: undefined, label: "" });
  const [dueDate, setDueDate] = useState<string | undefined>(undefined);
  const [ordenCompra, setOrdenCompra] = useState<string | undefined>(undefined);
  const [lote, setLote] = useState<string | undefined>(undefined);
  const [addNewLocationModalOpen, setAddNewLocationModalOpen] =
    useState<boolean>(false);
  const [amountPerContainer, setAmountPerContainer] = useState<number>(0);

  const { data } = useCrud<ArticleLocation>(ARTICLE_LOCATIONS_URL);
  const { createData: createLocation } = useCrud<LocationBase>(LOCATIONS_URL, {
    disableAutoFetch: true,
  });
  const { createData: createArticle } = useCrud<Article>(ARTICLES_URL, {
    disableAutoFetch: true,
  });

  const { addSessionData } = useSession();

  const locationOptions = useMemo(() => {
    return locations
      .map(({ id, code }) => ({ id, label: code }))
      .concat({ id: 0, label: "Nueva Ubicación" });
  }, [locations]);

  const handleCreateLocation = async (newLocation: LocationBase) => {
    const response = await createLocation(newLocation, true, true);

    if (response.status === STATUS_CODES.OK) {
      const createdLocation = response.data;
      addSessionData(ENTITY.LOCATIONS, response.data);
      setSelectedLocation({
        id: createdLocation.id,
        label: createdLocation.code,
      });
      setAddNewLocationModalOpen(false);
      showSnackBar("Se ha agregado la ubicación correctamente.", "success");
    } else {
      showSnackBar(
        "No se ha podido agregar la ubicación. Por favor intente nuevamente.",
        "error"
      );
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseInt(e.target.value));
  };

  const handleLocationChange = (
    _e: any,
    value: { id: number | undefined; label: string } | null
  ) => {
    setSelectedLocation(value ? value : { id: undefined, label: "" });
  };

  const isButtonDisabled = useCallback(() => {
    return Boolean(
      movement.articles.find(
        (art) =>
          art.article_id === articleInput.id &&
          art.location_id === selectedLocation.id
      ) ||
        !amount ||
        amount <= 0 ||
        !selectedLocation.id
    );
  }, [movement.articles, articleInput.id, selectedLocation, amount]);

  const handleSubmit = async () => {
    let location;
    let article = articleInput;

    if (
      amountPerContainer &&
      amountPerContainer !== articleInput.amount_per_container
    ) {
      const response = await createArticle({
        code: articleInput.code + "-" + amountPerContainer,
        description: `${articleInput.description} (AUTO GENERADO)`,
        amount_per_container: amountPerContainer,
        client_id: articleInput.client_id,
        unit_id: articleInput.unit_id,
        container_id: articleInput.container.id,
        weight: articleInput.weight,
        volume_weight: articleInput.volume_weight,
        amount: articleInput.amount,
      } as Article);

      if (response.status === STATUS_CODES.OK) {
        article = response.data;
        addSessionData(ENTITY.ARTICLES, response.data);
      }
    }

    if (selectedLocation?.id !== 0) {
      location = locations.find((l) => l.id === selectedLocation?.id);
    }

    if (!location) {
      return;
    } else if (location.id) {
      const articleLocations: ArticleLocation = {
        id: data[data.length - 1] ? data[data.length - 1].id + 1 : 1,
        article_id: article.id,
        location_id: location.id,
        article: article,
        location: location,
        stock: amount,
        reserved: 0,
      };

      const movArt: MovementArticle = {
        id: rowIndex,
        article_locations: articleLocations,
        article_locations_id: articleLocations.id,
        article_id: articleLocations.article_id,
        client_id: articleLocations.article.client.id,
        location_id: articleLocations.location_id,
        container: articleLocations.article.container.description,
        description: articleLocations.article.description,
        weight: article.weight,
        volume_weight: article.volume_weight,
        unit: articleLocations.article.unit.description,
        amount_per_container: articleLocations.article.amount_per_container,
        amount: amount,
        subtotal: articleLocations.article.amount_per_container * amount,
        subtotal_kg: amount * article.weight,
        lote: lote,
        oc: ordenCompra,
        fecha_vencimiento: dueDate,
      };
      onMovementArticleAdd(movArt);

      setAmount(1);
    }
  };

  useEffect(() => {
    if (selectedLocation?.id === 0) {
      setAddNewLocationModalOpen(true);
    }
  }, [selectedLocation]);

  return (
    <>
      <TableRow>
        <TableCell align="center">{articleInput.code}</TableCell>
        <TableCell align="center">{articleInput.description}</TableCell>
        <TableCell align="center">{articleInput.units_per_pallet}</TableCell>
        <TableCell align="center">
          <CustomNumberInput
            data-cy={`qty-${articleInput.code}`}
            label={`Cantidad a ingresar`}
            value={amount}
            onChange={handleAmountChange}
          />
        </TableCell>
        <TableCell align="center">
          <Autocomplete
            data-cy={`location-${articleInput.code}`}
            autoComplete
            disableClearable
            style={{ marginTop: 6 }}
            options={locationOptions}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            value={selectedLocation}
            onChange={handleLocationChange}
            renderInput={(params) => (
              <TextField {...params} required label="Seleccione" />
            )}
            renderOption={(props, option) => (
              <li {...props} data-cy={option.label}>
                {option.label}
              </li>
            )}
          />
        </TableCell>
        <TableCell align="center">
          <CustomNumberInput
            data-cy={`amount-per-container-${articleInput.code}`}
            label={`Cant. por Contenedor`}
            value={amountPerContainer}
            required
            onChange={(e) => setAmountPerContainer(parseInt(e.target.value))}
          />
        </TableCell>
        <TableCell align="center">
          <TextField
            data-cy={`lote-${articleInput.code}`}
            label="Lote"
            type="text"
            onChange={(e) => setLote(e.target.value)}
          />
        </TableCell>
        <TableCell align="center">
          <TextField
            data-cy={`order-${articleInput.code}`}
            label={`OC`}
            type="text"
            onChange={(e) => setOrdenCompra(e.target.value)}
          />
        </TableCell>
        <TableCell align="center">
          <DateTimePicker
            label="Fecha"
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            slots={{
              actionBar: DateTimePickerActionBar,
              textField: TextField,
            }}
            slotProps={{
              textField: {
                inputProps: {
                  "data-cy": `exp-date-${articleInput.code}`,
                },
              },
            }}
            views={DATE_VIEWS}
            onChange={(value: Date | null) => {
              try {
                if (value?.toISOString()) {
                  setDueDate(dateISOFormat(value.toISOString()) || undefined);
                }
              } catch (dateExcep) {
                return;
              }
            }}
          />
        </TableCell>
        <TableCell align="center">
          <Button
            data-cy={`add-mov-art-${articleInput.code}`}
            variant="contained"
            disabled={isButtonDisabled()}
            onClick={handleSubmit}
          >
            <RxPlus size={"1.2rem"} />
          </Button>
          {movement.store_id && (
            <AddLocationModal
              open={addNewLocationModalOpen}
              handleOpen={() => {
                setSelectedLocation({ id: undefined, label: "" });
                setAddNewLocationModalOpen(false);
              }}
              onCreate={handleCreateLocation}
              stores={stores.filter((store) => store.id === movement.store_id)}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
